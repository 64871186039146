<template>

    <div class="page-content">
        <div class="container-fluid">

            <Breadcrumb
                :pgTitle="$t('view.user_wallet')"
                :pgIcon="'bx bx-user'"
                :refs="refs"
                :addNew="false">
            </Breadcrumb>


            <MinDataTable
                :refs="refs"
                :colspan=9
                :user_id="this.$route.params.id"

                :hasSearchFilter=false
                :hasDateFilter=false

                :hasHashId=true
                :hasMultiSelect=false
                :hasBulkAction=false
                :HasExport=false
                :hasShowEntries=true
                :hasSearch=true

                :lblSearch="$t('app.search_by_name')"

                :hasPrice=true
                :price="$t('app.balance')"

                :hasCategory=true
                :category="$t('app.type')"
                :image_style="'width: 40px;height: 40px;border-radius: 50%'"

                :hasDate=true
                :hasBalance=true

                :actionable=true
                :sortable=true>
            </MinDataTable>

        </div>
    </div>

</template>

<script>
export default {
    name: 'Wallet',
    components: {
        Breadcrumb: () => import('@/components/Breadcrumb.vue'),
        MinDataTable: () => import('@/components/MinDataTable.vue')
    },
    data(){
        return {    
            //
            addNew: false,
            refs: 'users',
        }
    },
}
</script>
